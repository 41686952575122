import { 
  SET_CALCULATED_LAYOUT, 
  SET_VALUE, 
  LOG_IN, 
  LOG_OUT, 
  GET_PRODUCT_INFO, 
  ADD_PRODUCT_TO_CART, 
  SET_ERROR, 
  SET_CART_TOTAL, 
  GET_CART,
  CLEAR_ALL_ERRORS,
  SET_SHIPPING_ADDRESS,
  SET_BILLING_ADDRESS,
  SET_ADDRESSES_ON_CART,
  RESET_ADDRESSES_ON_CART,
  GET_BRAINTREE_TOKEN,
  PLACE_ORDER,
  GET_CATEGORY_TREE,
  GET_CATEGORY_INFO,
  PLACE_ORDER_OF_SALES_MANAGER,
  GET_COUNTRIES,
  GET_ADDRESSES,
  REMOVE_ITEM_FROM_CART,
  SEARCH_PRODUCTS,
  CREATE_CUSTOMER_ADDRESS,
  DELETE_CUSTOMER_ADDRESS,
  SET_CUSTOMER_ADDRESS_AS_DEFAULT,
  SELECT_SHIPPING_ADDRESS_ID,
  SELECT_BILLING_ADDRESS_ID,
  CREATE_CUSTOMER,
  RESET_STATE,
  GET_CUSTOMER_ORDERS,
  ACTIVATE_NEW_CUSTOMER,
  GET_FILES_FROM_STORAGE,
  GET_PRODUCTS_LIST,
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_ORDER_INFO,
  REQUEST_PASSWORD_RESET_EMAIL,
  RESET_PASSWORD,
  GET_ORDERS,
} from "./types";
import { calc } from '../layout';
import { createTheme } from '@mui/material/styles';
import { Color } from '../flatColors';
import axios from 'axios';

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Poppins",
      textTransform: 'none',
      color:"#333"
    }
  },
  palette: {
    primary: {
      main: "#6abdd2",
    },
    secondary: {
      main: Color.flatGreen,
    },
    text: {
      main: "#333",
    }
  },
});

const INITIAL_STATE = {
  theme: theme,
  loginResult:false,
  userEmail:"",
  userPassword:"",
  lt: calc(window.innerWidth, 4),
  api: "https://jrcastings.site/graphql",
  apiRest: "https://jrcastings.com/webapi.php",
  apiRestV1: "https://jrcastings.site/rest/default/V1",
  customerToken: localStorage.getItem('customerToken') ? localStorage.getItem('customerToken') : "",
  customer_group_sales_person:4,
  customerEmail: "",
  errMessage: "",
  errType:"",
  successMessage: "",
  productInfo: {},
  cart_id: "",
  cartTotal: 0, //qty
  cartGrandTotal: 0, //sum
  cart: {},
  shippingAddress: {},
  billingAddress: {},
  braintreeToken: "",
  placedOrder: {},
  categoryTree: [],
  appliedCoupons: null,
  isSalesManager: false, //if customer is a sales manager
  countries: [],
  selectedShippingAddressId: null,
  selectedBillingAddressId: null,
  addresses: [],
  customerCreated: "",
  customerOrders: {},
  searchedOrders: {},
  searchedProducts: [],
  customerActivateResponse: {},
  files3D:[],
  categoryInfo:{},
  productsList: {},
  customerDataFromGoogleSheet:[],
  customerOrderInfo:{},
  orderForPrint: {},
  customerInfo: {},
  resetPasswordResult:false,
  requestPasswordResetEmailResult:false,
  currentProduct:null,
  allOrders:"",
  sendMessage: (message) => {
    let chatId = 428542657;
    let token = "6268489926:AAHV1VfcAtbchHQvZkMzAzGM_90i7sc7YDs";
    let url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${message}`;
    axios.get(url);
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CALCULATED_LAYOUT:
      return {
        ...state,
        ...action.payload,
      };
    case LOG_IN:
      localStorage.setItem('customerToken', action.payload.customerToken);
      return {
        ...state,
        ...action.payload,
      };
    case LOG_OUT:
      localStorage.setItem('customerToken', "");
      return {
        ...state,
        ...action.payload,
      };
    case GET_PRODUCT_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_PRODUCT_TO_CART:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CART_TOTAL:
      return {
        ...state,
        ...action.payload,
      };
    case SET_VALUE:
      return {
        ...state,
        ...action.payload,
      };
    case GET_CART:
      return {
        ...state,
        ...action.payload,
      };
    case SET_SHIPPING_ADDRESS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_BILLING_ADDRESS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ADDRESSES_ON_CART:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_ADDRESSES_ON_CART:
      return {
        ...state,
        ...action.payload,
      };
    case GET_BRAINTREE_TOKEN:
      return {
        ...state,
        ...action.payload,
      };
    case PLACE_ORDER:
      return {
        ...state,
        ...action.payload,
      };
    case PLACE_ORDER_OF_SALES_MANAGER:
      return {
        ...state,
        ...action.payload,
      };
    case GET_CATEGORY_TREE:
      return {
        ...state,
        ...action.payload,
      };
    case GET_COUNTRIES:
      return {
        ...state,
        ...action.payload,
      };
    case GET_ADDRESSES:
      return {
        ...state,
        ...action.payload,
      };
    case CREATE_CUSTOMER_ADDRESS:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_CUSTOMER_ADDRESS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CUSTOMER_ADDRESS_AS_DEFAULT:
      return {
        ...state,
        ...action.payload,
      };
    case SELECT_SHIPPING_ADDRESS_ID:
      return {
        ...state,
        ...action.payload,
      };
    case SELECT_BILLING_ADDRESS_ID:
      return {
        ...state,
        ...action.payload,
      };
    case CREATE_CUSTOMER:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_ALL_ERRORS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case SEARCH_PRODUCTS:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIVATE_NEW_CUSTOMER:
      return {
        ...state,
        ...action.payload,
      };
    case GET_FILES_FROM_STORAGE:
      return {
        ...state,
        ...action.payload,
      };
    case GET_CATEGORY_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case GET_PRODUCTS_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case GET_CUSTOMER_ORDERS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_CUSTOMER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case GET_CUSTOMER_ORDER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case GET_ORDERS:
      return {
        ...state,
        ...action.payload,
      };
    case REQUEST_PASSWORD_RESET_EMAIL:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return INITIAL_STATE;
  }
};